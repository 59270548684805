<template>
  <div id="b2c" class="main-body">
    <div id="nav-white" />
    <div id="services-front" class="b2c-bg">
      <h1 class="zoomIn">Business-to-Customer (B2C)</h1>
    </div>

    <div id="content">
      <h1 class="fadeIn">
        Introducing MiniDC: Your Cool Solution for B2C Deliveries
      </h1>
      <p class="fadeIn">
        Welcome to MiniDC, where we bring the chill to your doorstep! Our
        specialized system is designed to handle cold chain delivery for all
        your B2C needs. Whether you're craving fresh groceries, or delicate
        goods, we've got you covered. With MiniDC, you can trust that your items
        will arrive crisp, cool, and perfectly preserved. Say goodbye to worries
        about temperature-sensitive products spoiling during transit. Our
        advanced technology ensures that everything from perishables to
        pharmaceuticals maintains their optimal conditions from warehouse to
        your hands. Experience the convenience and reliability of MiniDC for all
        your B2C deliveries. Keep it cool with us!
      </p>
    </div>

    <visit-system
      name="MINIDC"
      link="https://minidc.golog.my/login"
      class="fadeIn"
    />
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";
import VisitSystem from "@/components/VisitSystem.vue";

export default {
  components: { VisitSystem },
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0.5);
    this.setupIntersectionObserver(".zoomIn", 0.1);
  },
};
</script>
