<template>
  <b-col id="visit-system" align-h="center">
    <div class="d-flex justify-content-center">
      <img v-if="isB2b" src="@/assets/images/icons/main/b2b-logo.png" />
      <img v-else src="@/assets/images/icons/main/golog-minidc.png" />
    </div>

    <div class="my-2 text-center">
      <h1>Visit our {{ name }} system now</h1>
      <h6 class="mt-2">Click below to login or to contact us.</h6>
    </div>

    <div class="d-flex justify-content-center mt-2">
      <a class="login-button mr-1" :href="link"> Login to {{ name }} </a>
      <router-link class="sl-button sl-white" :to="{ name: 'Contact' }">
        Contact us
      </router-link>
    </div>
  </b-col>
</template>
<script>
export default {
  props: {
    name: String,
    link: String,
    isB2b: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
